import { Button, Flex } from "antd";
import PublicLayout from "../../layouts/PublicLayout/PublicLayout";

import BridgeLogoV3 from "../../assets/img/bridge-logo-v3.png";
import { useNavigate } from "react-router-dom";

export default function Landing() {
  const navigate = useNavigate();
  return (
    <PublicLayout>
      <Flex justify="center" align="center" vertical>
        <div>
          <Button
            type="link"
            onClick={() => {
              navigate("/");
            }}
            block
          >
            <img
              style={{ width: 275, marginBottom: 200 }}
              src={BridgeLogoV3}
              alt="bridge-logo-big"
            />
          </Button>
        </div>
        <div
          style={{
            marginTop: 0,
            fontSize: 16,
            fontWeight: "normal",
            width: 339,
            textAlign: "center",
            display: "flex",
          }}
        >
          <p>Fully Customizable AI-Powered Coaching For Your Business.</p>
        </div>
        <Button
          type="primary"
          block
          style={{ padding: 18, height: "auto", borderRadius: 20 }}
          onClick={() => {
            navigate("/login");
          }}
        >
          Get Started
        </Button>
      </Flex>
    </PublicLayout>
  );
}
