import { Button, Flex, Spin, Typography } from "antd";
import PrivateLayout from "../../layouts/PrivateLayout/PrivateLayout";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import useApi from "../../api/useApi";

var scenarioTimeout;

const { Title, Paragraph } = Typography;

export default function ScenarioSummery() {
  const id = useParams().id;
  const { api } = useApi(true);
  const navigate = useNavigate();
  const [scenario, setScenario] = useState(null);
  const [loading, setLoading] = useState(true);

  const getScenario = async () => {
    try {
      const {
        data: { scenario: sc },
      } = await api.get(`/api/scenario/${id}`);
      setScenario(sc);
    } catch (ex) {
      console.log({ ex });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (scenarioTimeout) {
      clearTimeout(scenarioTimeout);
    }
    setTimeout(getScenario, 500);
  }, [id]);

  return (
    <PrivateLayout>
      {loading ? (
        <Flex vertical justify="center" align="center"><Spin /></Flex>
      ) : (
        <Flex vertical justify="center">
          <Title level={3}>Scenario Details</Title>
          <Paragraph>
            <strong> Company :</strong> {scenario?.company}
          </Paragraph>
          <Paragraph>
            <strong>Title : </strong> {scenario?.title}
          </Paragraph>
          <Paragraph>
            <strong>Job Title : </strong>
            {scenario?.jobTitle}
          </Paragraph>
          <Paragraph>
            <strong>Scenario Specifics :</strong> {scenario?.scenarioSpecifics}
          </Paragraph>
          <br />
          <Title level={3}>Roleplay Character</Title>
          {scenario?.roleplays?.length > 0 &&
            scenario.roleplays.map((ele) => {
              return (
                <Paragraph key={ele._id}>
                  <strong> Description :</strong> {ele.description}
                </Paragraph>
              );
            })}
          <Button
            style={{ borderRadius: 22 }}
            size="large"
            type="primary"
            block
            onClick={() => {
              navigate("/scenario/chat/" + id);
            }}
          >
            {"Ready"}
          </Button>
        </Flex>
      )}
    
    </PrivateLayout>
  );
}
