import { Button, Divider, Flex, Space } from "antd";
import PrivateLayout from "../../layouts/PrivateLayout/PrivateLayout";
import CoachingTemplates from "./CoachingTemplates/CoachingTemplates";
import { useNavigate } from "react-router-dom";
import useAppStore from "../../store/useAppStore";
import { FaCogs, FaUser, FaUserLock } from "react-icons/fa";
import { useEffect, useState } from "react";
import useCoachingTemplatesStore from "../../store/useCoachingTemplatesStore";

export default function Home() {
  const navigate = useNavigate();
  const authenticatedUser = useAppStore((state) => state.authenticatedUser);
  const setTemplates = useCoachingTemplatesStore((state) => state.setTemplates);
  const logout = useAppStore((state) => state.logout);

  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (
      authenticatedUser &&
      authenticatedUser?.roles?.find(
        (role) => role.systemName === "administrator"
      )
    ) {
      setIsAdmin(true);
    }
  }, [authenticatedUser]);

  return (
    <PrivateLayout>
      <Flex vertical>
        <Flex align="center" justify="center">
          <Space>
            <Button onClick={() => navigate("/profile")} icon={<FaUser />}>
              My Profile
            </Button>
            {isAdmin ? (
              <Button onClick={() => navigate("/admin")} icon={<FaCogs />}>
                Administration
              </Button>
            ) : null}

            <Button
              type="primary"
              onClick={() => {
                setTemplates([]);
                logout();
                navigate("/login");
              }}
              icon={<FaUserLock />}
            >
              Logout
            </Button>
          </Space>
        </Flex>
        <Divider />
        <div style={{ fontSize: 16, fontWeight: "bold" }}>
          <p>Coaching via Roleplay</p>
        </div>

        <Button
          type="primary"
          block
          onClick={() => {
            navigate("/scenario");
          }}
        >
          Create a Custom Session
        </Button>

        <Divider style={{ width: "50%" }} />

        <CoachingTemplates />
      </Flex>
    </PrivateLayout>
  );
}
