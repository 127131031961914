import { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import styles from "../../../chatStyles/Home.module.css";
import ReactMarkdown from "react-markdown";
import useApi from "../../../api/useApi";
import BridgeLogoV3 from "../../../assets/img/bridge-logo-v3.png";
import userLogo from "../../../assets/img/usericon.png";
import {
  Avatar,
  Button,
  Card,
  Col,
  Flex,
  Input,
  Row,
  Skeleton,
  Spin,
} from "antd";
import userIcon from "../../../assets/img/usericon.png";

import { FaPaperPlane } from "react-icons/fa";

var chatTimeout;

const MessageRow = ({ index, role, roleplays, content }) => {
  return (
    <Row
      key={index}
      gutter={[10, 10]}
      justify={
        role === "assistant" || role === "coach" || role === "roleplay"
          ? "start"
          : "end"
      }
    >
      <Col>
        <Card
          style={
            role === "assistant" || role === "coach" || role === "roleplay"
              ? {
                  width: "auto",
                  margin: "40px 20px",
                  borderColor: "#D71E1E",
                }
              : { width: "auto", margin: "40px 20px", borderColor: "#fff" }
          }
        >
          <div
            style={
              role === "assistant" || role === "coach" || role === "roleplay"
                ? { position: "absolute", top: -40, left: 0 }
                : { position: "absolute", top: -40, right: 0 }
            }
          >
            {role === "assistant" || role === "coach" ? (
              <img src={BridgeLogoV3} alt="square" style={{ width: 80 }} />
            ) : role === "roleplay" ? (
              <Avatar.Group>
                {roleplays.map((role, index) => (
                  <Avatar
                    key={"role-play" + index}
                    src={role.photoUrl ? role.photoUrl : userIcon}
                  />
                ))}
              </Avatar.Group>
            ) : (
              <Avatar src={userLogo} />
            )}
          </div>
          <div className={styles.markdownanswer}>
            {/* Messages are being rendered in Markdown format */}
            <ReactMarkdown>{content}</ReactMarkdown>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default function Chat({ chat_data }) {
  let { api } = useApi(true);
  const [userInput, setUserInput] = useState("");
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const [scenario, setScenario] = useState(null);

  const id = useParams().id;
  const messageListRef = useRef(null);
  const textAreaRef = useRef(null);

  const getScenario = async (id) => {
    try {
      const {
        data: { scenario: sc },
      } = await api.get(`/api/scenario/${id}`);
      setScenario(sc);
    } catch (ex) {
      console.log({ ex });
    }
  };

  // Auto scroll chat to bottom
  useEffect(() => {
    if (messageListRef.current) {
      const messageList = messageListRef.current;
      messageList.scrollTop = messageList.scrollHeight;
    }
  }, [messages]);

  // Focus on input field
  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, []);

  // Handle errors
  const handleError = () => {
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        role: "assistant",
        content: "Oops! There seems to be an error. Please try again.",
      },
    ]);
    setLoading(false);
    setUserInput("");
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (userInput.trim() === "") {
      return;
    }

    // Reset user input
    setUserInput("");

    setLoading(true);
    const context = [...messages, { role: "user", content: userInput }];
    setMessages(context);

    // Send chat history to API
    const response = await api.post("/api/scenario/chat", {
      messages: context,
      id,
    });

    const data = await response.data;

    if (!data.result) {
      handleError();
      return;
    }

    setMessages((prevMessages) => [
      ...prevMessages,
      { role: "assistant", content: data.result.content },
    ]);
    setLoading(false);
  };

  // Prevent blank submissions and allow for multiline input
  const handleEnter = (e) => {
    if (e.key === "Enter" && userInput) {
      if (!e.shiftKey && userInput) {
        handleSubmit(e);
      }
    } else if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (chatTimeout) {
      clearTimeout(chatTimeout);
    }

    chatTimeout = setTimeout(async () => {
      try {
        if (id) await getScenario(id);
        const context = [
          ...messages,
          { role: "user", content: "Hi @Coach lets get started." },
        ];
        setMessages(context);
        setLoading(true);
        // Send chat history to API
        const response = await api.post("/api/scenario/chat", {
          messages: context,
          id,
        });

        // Reset user input
        setUserInput("");
        const data = await response.data;
        setLoading(false);
        if (!data.result) {
          handleError();
          return;
        }

        setMessages((prevMessages) => [
          ...prevMessages,
          { role: "assistant", content: data.result.content },
        ]);
      } catch (ex) {
        console.log({ ex });
      } finally {
        setLoading(false);
      }
    }, 500);
  }, []);

  return (
    <>
      <div className={styles.main}>
        <div className={styles.cloud}>
          <div ref={messageListRef} className={styles.messagelist}>
            {messages.map((message, index) => {
              const { role, content } = message;

              const responses = content.split("[Coach]");
              const hasHint = responses.length > 1 ? true : false;

              const toReturn = responses.map((res, resIndex) => {
                return res !== "" ? (
                  <MessageRow
                    key={"message-row-res" + resIndex}
                    index={resIndex}
                    role={
                      !hasHint
                        ? "assistant"
                        : hasHint && resIndex === 0
                        ? "roleplay"
                        : hasHint && resIndex === 1
                        ? "coach"
                        : "roleplay"
                    }
                    content={resIndex === 1 ? `[Coach]${res}` : res}
                    roleplays={scenario.roleplays}
                  />
                ) : null;
              });

              return role === "assistant" ? (
                toReturn
              ) : (
                <MessageRow
                  key={"message-row" + index}
                  index={index}
                  role={role}
                  content={content}
                  roleplays={scenario.roleplays}
                />
              );
            })}
            {loading && !messages.length ? (
              <Skeleton style={{ padding: "1.5rem" }} active />
            ) : null}
            {/* Skeleton */}
          </div>
        </div>
        <div className={styles.center}>
          <div className={styles.cloudform}>
            <form onSubmit={handleSubmit}>
              <Flex>
                <Input.TextArea
                  disabled={loading}
                  onKeyDown={handleEnter}
                  ref={textAreaRef}
                  autoFocus={false}
                  rows={1}
                  id="userInput"
                  name="userInput"
                  placeholder={
                    loading
                      ? "Waiting for response..."
                      : "Type your question..."
                  }
                  value={userInput}
                  onChange={(e) => setUserInput(e.target.value)}
                  // className={styles.textarea}
                  style={{ width: "calc(100% - 110px)", height: "12vh" }}
                  size="large"
                />
                <Flex vertical justify="space-between">
                  <div>
                    <Flex
                      justify="center"
                      align="center"
                      style={{ textAlign: "center" }}
                    >
                      {loading ? <Spin /> : null}
                    </Flex>
                  </div>
                  <div>
                    <Button
                      htmlType="submit"
                      disabled={loading}
                      icon={<FaPaperPlane />}
                      type="primary"
                      shape="circle"
                      style={{ marginLeft: 20 }}
                      size="large"
                    />
                  </div>
                </Flex>
              </Flex>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
