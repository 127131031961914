import { Flex, Divider } from "antd";

import PrivateLayout from "../../layouts/PrivateLayout/PrivateLayout";
import ProfileForm from "../../forms/ProfileForm/ProfileForm";

export default function Profile() {
  return (
    <PrivateLayout>
      <Flex justify="center" align="center" vertical>
        <div style={{ fontSize: 16, fontWeight: "bold" }}>
          <p>Profile</p>
        </div>
        <Divider />
        <ProfileForm />
      </Flex>
    </PrivateLayout>
  );
}
