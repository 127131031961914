import { Card, Col, Flex, Row, Divider } from "antd";
import AdminLayout from "../../layouts/AdminLayout/AdminLayout";
import { FaUsers } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export default function Admin() {
  const navigate = useNavigate();
  return (
    <AdminLayout>
      <Card
        title={"Administration"}
        style={{
          margin: 10,
          width: "calc(100% - 20px)",
          minHeight: "calc(100vh - 260px)",
          background: "transparent",
        }}
      >
        <Row>
          <Col span={4}>
            <Card hoverable onClick={()=>{navigate('/admin/users')}}>
              <Flex vertical align="center" justify="center">
                <FaUsers style={{ fontSize: 100 }} />
                <Divider />
                <h4>Users</h4>
              </Flex>
            </Card>
          </Col>
        </Row>
      </Card>
    </AdminLayout>
  );
}
