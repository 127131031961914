import PrivateLayout from "../../layouts/PrivateLayout/PrivateLayout";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useCoachingTemplatesStore from "../../store/useCoachingTemplatesStore";

import Chat from "./Chat/Chat";

export default function ScenarioChat() {
  const id = useParams().id;

  const { templates } = useCoachingTemplatesStore((state) => state);

  const [scenario, setScenario] = useState(
    templates.find((e) => e?._id === id) || {}
  );

  useEffect(() => {
    setScenario(templates.find((e) => e._id === id));
  }, [templates]);

  return (
    <PrivateLayout>
      <Chat chat_data={scenario} />
    </PrivateLayout>
  );
}
