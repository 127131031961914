import { Divider, Flex, Spin, Typography } from "antd";
import PrivateLayout from "../../layouts/PrivateLayout/PrivateLayout";
import ScenarioForm from "../../forms/ScenarioForm/ScenarioForm";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import useApi from "../../api/useApi";

const { Title, Paragraph } = Typography;

var scenarioTimeout;

export default function Scenario() {
  const [loading, setLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const { id } = useParams();

  const { api } = useApi(true);

  const getScenario = async () => {
    if (id) {
      try {
        setLoading(true);
        const {
          data: { scenario },
        } = await api.get(`/api/scenario/${id}`);
        setSelectedRecord(scenario);
      } catch (ex) {
        console.log({ ex });
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (scenarioTimeout) {
      clearTimeout(scenarioTimeout);
    }

    setTimeout(getScenario, 500);
  }, [id]);

  return (
    <PrivateLayout>
      <Flex vertical justify="center">
        <Title level={3}>Scenario Setup</Title>
        <Paragraph>
          Fill in the following fields to create a custom scenario. If you leave
          any field blank, we will generate data for it if needed during the
          roleplay.
        </Paragraph>

        <Divider />

        {loading && !selectedRecord ? (
          <Spin />
        ) : (
          <ScenarioForm record={selectedRecord} />
        )}
      </Flex>
    </PrivateLayout>
  );
}
