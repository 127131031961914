import {
  Col,
  Row,
  List,
  Button,
  Flex,
  Input,
  Space,
  Card,
  Avatar,
  Popconfirm,
} from "antd";
import useSessionsStore from "../../../store/useCoachingTemplatesStore";
import { FaSearch, FaTrash } from "react-icons/fa";

import { useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";
import useCoachingTemplatesStore from "../../../store/useCoachingTemplatesStore";
import useAppStore from "../../../store/useAppStore";
import useApi from "../../../api/useApi";
import { fetchCoachingTemplates } from "../../../helpers/helpers";

import userIcon from "../../../assets/img/usericon.png";

export default function CoachingTemplates() {
  const templates = useSessionsStore((state) => state.templates);
  const navigate = useNavigate();

  const { setTemplates } = useCoachingTemplatesStore((state) => state);
  const authenticatedUser = useAppStore((state) => state.authenticatedUser);
  let { api } = useApi(true);

  const [loading, setLoading] = useState(false);

  const fetchScenarios = async () => {
    try {
      setLoading(true);
      let list = await fetchCoachingTemplates(authenticatedUser._id, api);
      setTemplates(list);
    } catch (err) {
      console.log({ err });
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleScenarioHide = async (values) => {
    setLoading(true);
    const { data } = await api.get("/api/scenario/hide/" + values._id);
    if (data.status) {
      fetchScenarios();
    }
  };

  useEffect(() => {
    if (authenticatedUser?._id) fetchScenarios();
  }, [authenticatedUser]);

  return (
    <Row gutter={[10, 10]}>
      <Col span={24}>
        <Flex vertical justify="center">
          <div style={{ fontSize: 16, fontWeight: "bold" }}>
            <p>Coaching Templates</p>
          </div>

          <div style={{ textAlign: "center", marginTop: 30, marginBottom: 30 }}>
            <Input
              prefix={<FaSearch style={{ color: "#D71E1E" }} />}
              style={{ width: "80%" }}
              placeholder="Search"
            />
          </div>
          <List
            loading={loading}
            itemLayout="horizontal"
            dataSource={templates}
            renderItem={(item, index) => {
              return (
                <Card
                  styles={{ body: { padding: "10px 20px" } }}
                  style={{ margin: 20, borderRadius: 15 }}
                >
                  <List.Item
                    actions={[
                      <Button
                        key={`btn-action-go-${item._id}`}
                        type="primary"
                        size="small"
                        onClick={() => {
                          navigate(`/scenario/${item._id}`);
                        }}
                        shape="round"
                      >
                        Go
                      </Button>,
                      <Popconfirm
                        key={`btn-action-del-${item._id}`}
                        onCancel={() => {}}
                        onConfirm={() => {
                          handleScenarioHide(item);
                        }}
                        okText="Yes"
                        cancelText="No"
                        title="Are you Sure?"
                        description="Are you sure you want to remove this scenario?"
                      >
                        <Button
                          shape="circle"
                          icon={<FaTrash />}
                          size="small"
                        />
                      </Popconfirm>,
                    ]}
                  >
                    <List.Item.Meta
                      avatar={
                        item.roleplays[0] && item.roleplays[0].photoUrl ? (
                          <Avatar src={item.roleplays[0].photoUrl} />
                        ) : (
                          <Avatar src={userIcon} alt="default-user-img" />
                        )
                      }
                      title={item.title}
                    />
                  </List.Item>
                </Card>
              );
            }}
          />
        </Flex>
      </Col>
    </Row>
  );
}
